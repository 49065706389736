import React, { useEffect, useState } from 'react';
import { ReferenciaCabecera } from '../../../interfaces/ReferenciaCabecera';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import transitionElement from '../../../utils/transitionElement';
import logoBlanco from '../../../assets/images/logos/comfama/logo-white.svg';
import './headboardTrees.scss';

const HeadboardTrees = (props: ReferenciaCabecera) => {
    const { isBrowser, document, window } = useBrowserMode();
    const [scrollTitle, setScrollTitle] = useState<any>();
    const [scrollImg1, setScrollImg1] = useState<any>();
    const [scrollImg2, setScrollImg2] = useState<any>();
    const [scrollImg3, setScrollImg3] = useState<any>();
    const [scrollImg4, setScrollImg4] = useState<any>();

    useEffect(() => {
        setTimeout(() => {
            setScrollTitle(document.getElementById('parallax-title-arboles'));
            setScrollImg1(document.getElementById('parallax-img1-arboles'));
            setScrollImg2(document.getElementById('parallax-img2-arboles'));
            setScrollImg3(document.getElementById('parallax-img3-arboles'));
            setScrollImg4(document.getElementById('parallax-img4-arboles'));
        }, 1000);
    }, []);
    if (isBrowser) {
        window.addEventListener('scroll', () => {
            let scrollValue = window.scrollY;
            if (scrollTitle)
                scrollTitle.style.marginTop = scrollValue * 0.6 + 'px';
            if (scrollImg1)
                scrollImg1.style.marginLeft = scrollValue * -0.9 + 'px';
            if (scrollImg2)
                scrollImg2.style.marginLeft = scrollValue * -1.3 + 'px';
            if (scrollImg2)
                scrollImg2.style.marginBottom = scrollValue * -1.3 + 'px';
            if (scrollImg3)
                scrollImg3.style.marginRight = scrollValue * -0.9 + 'px';
            if (scrollImg4)
                scrollImg4.style.marginRight = scrollValue * -1.3 + 'px';
            if (scrollImg4)
                scrollImg4.style.marginBottom = scrollValue * -1.3 + 'px';
        });

        transitionElement();

        document.addEventListener('mousemove', parallax);
    }

    function parallax(e) {
        document.body
            .querySelectorAll('.tree-img--parallax')
            .forEach((image) => {
                const speed = image.getAttribute('data-speed');
                const y = (window.innerHeight - e.pageY * speed) / 80;
                const x = (window.innerWidth - e.pageX * speed) / 100;
                switch (+speed) {
                    case 1:
                        image.style.transform = `translateX(${x}px)`;
                        return;
                    case 3:
                        image.style.transform = `translateX(${
                            1.5 * x
                        }px) translateY(${-y}px) scaleX(-1)`;
                        return;
                    case 2:
                        image.style.transform = `translateX(${-x}px) scaleX(-1)`;
                        return;
                    default:
                        //case 4
                        image.style.transform = `translateX(${
                            1.5 * -x
                        }px) translateY(${-y}px)`;
                        return;
                }
            });
    }

    return (
        <div className="o-headboard-trees">
            <a
                href="/"
                target="_self"
                rel="referrer follow"
                className="o-headboard-trees__home"
            >
                <img loading="lazy" alt="" src={logoBlanco} />
            </a>
            <img
                loading="lazy"
                className="o-headboard-trees__fixed"
                alt=""
                src={props.imagenesArboles[5].file.url}
            />
            <img
                src={props.imagenesArboles[0].file.url}
                alt={props.imagenesArboles[0].file.fileName}
                className="o-headboard-trees__bg"
            />
            <h1 className="o-headboard-trees__title">
                {props.tituloPrincipal}
            </h1>
            <img
                id="parallax-title-arboles"
                src={props.imagenesArboles[1].file.url}
                alt={props.imagenesArboles[1].file.fileName}
                className="o-headboard-trees__img-title slow-appear"
            />
            <img
                id="parallax-img1-arboles"
                src={props.imagenesArboles[2].file.url}
                alt={props.imagenesArboles[2].file.fileName}
                className="o-headboard-trees__img-left slow-appear tree-img--parallax"
                data-speed="1"
            />
            <img
                id="parallax-img2-arboles"
                src={props.imagenesArboles[3].file.url}
                alt={props.imagenesArboles[3].file.fileName}
                className="o-headboard-trees__img-left slow-appear tree-img--parallax"
                data-speed="3"
            />
            <img
                id="parallax-img3-arboles"
                src={props.imagenesArboles[2].file.url}
                alt={props.imagenesArboles[2].file.fileName}
                className="o-headboard-trees__img-right slow-appear tree-img--parallax"
                data-speed="2"
            />
            <img
                id="parallax-img4-arboles"
                src={props.imagenesArboles[3].file.url}
                alt={props.imagenesArboles[3].file.fileName}
                className="o-headboard-trees__img-right slow-appear tree-img--parallax"
                data-speed="4"
            />
        </div>
    );
};

export default HeadboardTrees;
